/** Overrides and tweaks to PrimeNG styles */

// Use up all available width
 

// Override PrimeNG's default style to match bootstrap
.p-dropdown,
.p-checkbox > .p-checkbox-box,
.p-multiselect,
.p-autocomplete > ul.p-inputtext,
.p-autocomplete > input.p-inputtext,
.p-calendar > input {
  border-color: var(--bs-border-color);
  // border-radius: var(--bs-border-radius);
  border: 1px solid #a6a6a6;
}

.p-dropdown {
  width: 130px;
}
.p-multiselect {
  width: 160px;
}

.p-icon-wrapper {
  pointer-events: unset !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
    margin-left: -0.5rem;
}

.p-multiselect-panel .p-multiselect-header {
  padding: .429rem .3674rem;
}




// Coordinate invalid red color
p-autocomplete.ng-untouched.ng-invalid > .p-autocomplete > .p-inputtext,
p-dropdown.ng-untouched.ng-invalid > .p-dropdown,
p-multiselect.ng-untouched.ng-invalid > .p-multiselect,
p-calendar.ng-untouched.ng-invalid > .p-calendar > input {
  border-left: 5px solid var(--res-color-red-light);
}

// Coordinate invalid red color
p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext,
p-dropdown.ng-touched.ng-invalid > .p-dropdown,
p-calendar.ng-touched.ng-invalid > .p-calendar > input {
  border-left: 5px solid var(--res-color-red);
}

// Use Corteva's preferred font
:root {
  --font-family: var(--res-body-font-family);
}


.p-accordion-tab {
  margin-bottom: 20px;
}

.p-calendar {
  width: 100%;
}

.p-calendar > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:not(.p-dialog-footer) > .p-button :not(.p-selectbutton) > .p-button {
  border: #a9a9a9;
  background: #a9a9a9;
}

.p-column-filter-buttonbar > .p-button,
.p-column-filter-add-rule > .p-button {
  background: var(--bs-primary);
  color: var(--bs-body-bg);
}

.p-column-filter-buttonbar > .p-button-outlined {
  border: var(--bs-primary) solid 1px;
  background: var(--bs-body-bg);
  color: var(--bs-primary);
}

.p-paginator {
  justify-content: end;
  padding-right: 10px;
}
.p-datatable-table {
  margin: 0;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--bs-success);
}

