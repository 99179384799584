input:not([type='checkbox']):not([type='radio']).ng-invalid.ng-untouched,
textarea.ng-invalid.ng-untouched,
select.ng-invalid.ng-untouched {
  border-left: 5px solid var(--res-color-red-light);
}

input:not([type='checkbox']):not([type='radio']).ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
select.ng-invalid.ng-touched {
  border-left: 5px solid var(--res-color-red);
}

// Research Overrides
input,
textarea,
select,
option {
  font-family: var(--res-body-font-family);
}
