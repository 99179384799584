/* You can add global styles to this file, and also import other style files */
/* Core Grid CSS */
/** Using CSS Layers for more control over which styles have precedence
 * https://primeng.org/guides/csslayer
 * https://github.com/sass/sass/issues/3240
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@layer
 *
 * CSS supports "layers". Styles in a layer will always come before and can be overwritten by styles not in a layer.
 *
 * PrimeNG's stylesheets are defined in a "primeng" layer, so its styles will
 * always come before others and be overwritten.
 *
 * Therefore research and bootstrap styles need to be put in layers so that
 * they all have the same base precedence and all styles' actual specificities are used.
 */

 @use 'sass:meta';


 @import '../node_modules/@corteva-research/ngx-components-core/assets/styles/research-web-components.scss'
 layer(research);
 
 // PrimeNG's stylesheets specify their own layers
 
 @import '../node_modules/primeflex/primeflex.css';
 @import '../node_modules/primeng/resources/primeng.css';
 @import '../node_modules/primeicons/primeicons.css';
 @import '../node_modules/primeng/resources/themes/nova/theme.css';
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

 
 @layer bootstrap {
    @include meta.load-css('styles/bootstrap');
  }

@import '../node_modules/@corteva-research/ngx-components-core/assets/fonts/fonts.scss'
layer(research-fonts);

// Subsequent un-layered styles will overwrite styles in layers
@import 'styles/primeng.scss';
@import 'styles/corteva.scss';



 

 /* Core Grid CSS */
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import '../node_modules/ag-grid-community/styles/ag-theme-balham.css';
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
 /* This has to be loaded with @include meta.load-css because it has mixins that
  * won't be resolved when using @include '...' layer(layer)
 */


 body { min-width: 980px; }
:host ::ng-deep .group-content .group-content-wrapper {
    border: black;
    border-width: 1px;
    border-style: solid;
}

.headerPanelSmall .p-dialog-header {
  padding: 0rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.headerPanelMedium .p-dialog-header {
  padding: 0.5rem;
  padding-left: 1rem;
}

.geModal .p-dialog-content{
  overflow: hidden;
}

.p-icon-wrapper {
  pointer-events: unset !important;
}

.p-sidebar .p-sidebar-header {
  background-color: lightgray !important;
}

:host ::ng-deep .p-panel .p-panel-header {
    color: #007ad9;
    font-size: 17px;
    height: 45px;
}

.reportModal .p-dialog-header {
  height: 45px !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  /* padding: 0.2145rem 0.429rem; */
  padding-left: 0.16rem;
  padding-right: 0.15rem;
  margin-right: 0.5rem;
  background: #c8c8c8;
  color: #333333;
  border-radius: 3px;
  font-size: 14px;
    height: 22px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.17rem;
}



.p-overlaypanel {
    top: 120px !important;
    left: 500px !important;
    --overlayArrowLeft: 0px !important;
  }

  
  
  /* ----------- Non-Retina Screens ----------- */
  @media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1299px) 
    and (max-device-height: 850px) 
    and (-webkit-min-device-pixel-ratio: 1) { 
      
   .p-overlaypanel {
    z-index: 1001;
    transform-origin: center top;
    top: 120px !important;
    left: 500px;
    --overlayArrowLeft: 515px;
    transform: translateY(0px);
    opacity: 1;
  
  }

 .geModal .p-dialog-title {
    width: 150px !important;
}
      
  }
  
  
  
  @media screen 
    and (min-device-width: 1300px) 
    and (max-device-width: 1500px) 
    and (-webkit-min-device-pixel-ratio: 1) {  
  
      
   .p-overlaypanel {
      margin-top: 10px;
      z-index: 1002;
      transform-origin: center bottom;
      top: 120px !important;
      left: 500px;
      --overlayArrowLeft: 0px !important;
      transform: translateY(0px);
      opacity: 1;
  }
     
      
  }
  
  
  /* ----------- Retina Screens ----------- */
  @media screen 
    and (min-device-width: 1300px) 
    and (max-device-width: 1550px) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (min-resolution: 192dpi) {
  
    
   .p-overlaypanel {
      margin-top: 10px;
      z-index: 1002;
      transform-origin: center bottom;
      top: 120px !important;
      left: 500px;
      --overlayArrowLeft: 0px !important;
      transform: translateY(0px);
      opacity: 1;
  }
      
  }
  
  @media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1299px) 
    and (max-device-height: 850px) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (min-resolution: 192dpi)  { 
  
   
   .p-overlaypanel {
      margin-top: 10px;
      z-index: 1002;
      transform-origin: center bottom;
      top: 120px !important;
      left: 500px;
      --overlayArrowLeft: 0px !important;
      transform: translateY(0px);
      opacity: 1;
  }

   .geModal .p-dialog-title {
    width: 150px !important;
}

  }
