/** Picking and choosing which bootstrap modules to include in order to prevent
 * it from overwriting PrimeNG styles
 *
 * https://getbootstrap.com/docs/5.3/customize/sass/
 */

// ~~~~~~~~~
// This section copied from research-web-components.bootstrap.scss in order to
// do our own bootstrap imports
@import '../../node_modules/@corteva-research/ngx-components-core/assets/styles/colors';

$white: $res-color-white;
$gray-100: $res-color-gray-light;
$gray-200: #ccced0;
$gray-300: #b3b4b6;
$gray-400: #999a9c;
$gray-500: #808082;
$gray-600: #666668;
$gray-700: #4d4d4e;
$gray-800: #333334;
$gray-900: #1a1a1a;
$black: $res-color-black;

$blue: $res-color-hero;
$indigo: $res-color-indigo;
$purple: $res-color-violet;
$pink: $res-color-pink;
$red: $res-color-red;
$orange: $res-color-orange;
$yellow: $res-color-yellow;
$green: $res-color-green;
$teal: $res-color-teal;
$cyan: $res-color-cyan;

$primary: $blue;
$secondary: $gray-300;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $black;

$body-bg: $res-color-white;
$body-color: $res-color-black;

$grid-gutter-width: $res-grid-gutter;
$spacer: 1rem;

// $enable-rounded: false;

// .card-header {
//   padding-bottom: $card-spacer-y - $card-border-width;
// }

// ~~~~~~~~~

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
// @import '../../node_modules/bootstrap/scss/forms';
// @import '../../node_modules/bootstrap/scss/buttons';
// @import '../../node_modules/bootstrap/scss/transitions';
// // @import '../../node_modules/bootstrap/scss/dropdown';
// @import '../../node_modules/bootstrap/scss/button-group';
// @import '../../node_modules/bootstrap/scss/nav';
// @import '../../node_modules/bootstrap/scss/navbar';
// @import '../../node_modules/bootstrap/scss/card';
// @import '../../node_modules/bootstrap/scss/accordion';
// @import '../../node_modules/bootstrap/scss/breadcrumb';
// @import '../../node_modules/bootstrap/scss/pagination';
// @import '../../node_modules/bootstrap/scss/badge';
// @import '../../node_modules/bootstrap/scss/alert';
// @import '../../node_modules/bootstrap/scss/progress';
// @import '../../node_modules/bootstrap/scss/list-group';
// @import '../../node_modules/bootstrap/scss/close';
// @import '../../node_modules/bootstrap/scss/toasts';
// @import '../../node_modules/bootstrap/scss/modal';
// @import '../../node_modules/bootstrap/scss/tooltip';
// @import '../../node_modules/bootstrap/scss/popover';
// @import '../../node_modules/bootstrap/scss/carousel';
// @import '../../node_modules/bootstrap/scss/spinners';
// @import '../../node_modules/bootstrap/scss/offcanvas';
// @import '../../node_modules/bootstrap/scss/placeholders';
// @import '../../node_modules/bootstrap/scss/helpers';


// @import '../../node_modules/bootstrap/scss/utilities/api';

// These are the modules that overwrite PrimeNG's tables
// @import '../../node_modules/bootstrap/scss/reboot';
// @import '../../node_modules/bootstrap/scss/type';
// @import '../../node_modules/bootstrap/scss/tables';

/** Subsequent styles are custom overrides */

// Show the down-arrow on dropdown boxes

